import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import WalletConnectProvider from "@walletconnect/web3-provider";
import "./SeedView.css";
import {
  disconnect,
  connectAsync,
  connectWalletConnect,
  selectWeb3Status,
  selectWeb3MinStatus,
} from "./features/wallet/web3Slice";
import logo from "./assets/icons/swaraj.png";
// function ProgressBar(props) {
//   const width = props.percent + "%";
//   return (
//     <div style={{ paddingTop: "1px" }} className="sd">
//       <div className="progressBar">
//         <div style={{ width: width }}> </div>
//       </div>
//     </div>
//   );
// }

//FIREBASE
const firebaseConfig = {
  apiKey: "AIzaSyAoYE15h6p-R2o9UkaSp7I3fG3TapAPEdQ",
  authDomain: "swarajcoin-6836c.firebaseapp.com",
  databaseURL: "https://swarajcoin-6836c-default-rtdb.firebaseio.com",
  projectId: "swarajcoin-6836c",
  storageBucket: "swarajcoin-6836c.appspot.com",
  messagingSenderId: "946043743967",
  appId: "1:946043743967:web:e8bfa440b7b8ecfb57036a",
  measurementId: "G-XPTB209FJD",
};
const app = initializeApp(firebaseConfig);

export function SeedView() {
  const ref = React.useRef(null);
  const [id, setId] = useState(0);
  const [lots, setLots] = useState(0);
  const [deposit, setDeposit] = useState(0);
  const web3Status = useSelector(selectWeb3Status);
  const web3MinStatus = useSelector(selectWeb3MinStatus);
  const dispatch = useDispatch();
  const fetchHoldings = async (arg) => {
    console.log(web3MinStatus);
    let address = 0;
    if (arg === "Metamask") {
      await window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((data) => {
          address = data[0];
          console.log("address obtained from metamask: ", address);
        })
        .catch((err) => {
          if (err.code === 4001) {
            // EIP-1193 userRejectedRequest error
            // If this happens, the user rejected the connection request.
            console.log("Please connect to MetaMask.");
          } else {
            console.error(err);
          }
        });
    } else if (arg === "WalletConnect") {
      const provider = new WalletConnectProvider({
        infuraId: "b9d586efbb3546afbf364a7d840f40c2",
      });
      await provider.enable().then((response) => {
        address = response[0];
      });
    }
    //Firebase
    const db = getFirestore();
    address = address.toLowerCase();
    console.log("address: ", address);
    const docRef = doc(db, "user", address);
    const docSnap = await getDoc(docRef);
    console.log("docSnap: ", docSnap);
    console.log("docSnap.data(): ", docSnap.data());
    console.log("docSnap.exists(): ", docSnap.exists());
    setId(address);

    if (docSnap.exists()) {
      setLots(docSnap.data().PL);
      setDeposit(docSnap.data().method);
      console.log("Document data:", docSnap.data());
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  };
  useEffect(() => {
    if (localStorage.store) {
      if (JSON.parse(localStorage.store).web3.provider === "Metamask") {
        fetchHoldings("Metamask");
        console.log("Fetching Holdings");
      } else if (
        JSON.parse(localStorage.store).web3.provider === "WalletConnect"
      ) {
        fetchHoldings("WalletConnect");
        console.log("Fetching Holdings");
      }
    }
  }, []);
  return (
    <div ref={ref} className="viewSeedBody">
      <img className="seedPageLogo" src={logo} alt="Swaraj Coin" />
      <div
        className="web3StatusDisplay"
        style={
          web3MinStatus === "Metamask" || web3MinStatus === "WalletConnect"
            ? { backgroundColor: "#1daefd" }
            : { backgroundColor: "#a64af766" }
        }
      >
        {web3MinStatus}
      </div>
      <div
        style={
          web3MinStatus === "Metamask" || web3MinStatus === "WalletConnect"
            ? { display: "none" }
            : { display: "block" }
        }
        className="connectContainer"
      >
        <button
          className="connectButton"
          onClick={async () => {
            await dispatch(connectAsync());
            fetchHoldings("Metamask");
          }}
        >
          Connect Metamask
        </button>
        <br />
        <button
          className="connectButton"
          onClick={async () => {
            await dispatch(connectWalletConnect());
            fetchHoldings("WalletConnect");
          }}
        >
          WalletConnect
        </button>
        <br />
        <span>{web3Status}</span>
      </div>
      <div></div>
      <div
        style={
          web3MinStatus === "Metamask" || web3MinStatus === "WalletConnect"
            ? { display: "block" }
            : { display: "none" }
        }
        className="progressContainer"
      >
        <label>Wallet Address: {id}</label>{" "}
        <button
          className="disconnectButton"
          onClick={() => dispatch(disconnect())}
        >
          Disconnect
        </button>
        <br />
        <br />
        <label>Purchased Lots</label>
        <h3>
          {parseInt(lots)}
          <small style={{ color: "#a64af7" }}>LOTS</small>
        </h3>
        <label>Tokens Owed</label>
        <h3>
          {parseInt(lots) * 1240310}
          <small style={{ color: "#a64af7" }}>SWRJ</small>
        </h3>
        {/* <ProgressBar percent={80} /> */}
      </div>
      <div id="stars"></div>
      <div id="stars2"></div>
      <div id="stars3"></div>
    </div>
  );
}

export default SeedView;
